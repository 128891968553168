import {useEffect, useRef, useState} from "react";
import styles from './dialog.module.scss'

export const ModalWindow = ({children, isWindowOpen, setIsWindowOpen, wrapperClassName}) => {
    const modalRef = useRef(null)
    const wrapperRef = useRef(null)

    useEffect(() => {
        if (isWindowOpen) document.body.style.overflow = 'hidden'
        else document.body.style.overflow = 'auto'

        const handleClick = (event) => {
            if (modalRef?.current && !modalRef.current.contains(event.target) && isWindowOpen) {
                setIsWindowOpen(false)
            }
        }

        if (wrapperRef?.current) wrapperRef.current.addEventListener("mousedown", handleClick)

        return () => wrapperRef?.current?.removeEventListener("mousedown", handleClick)
    }, [modalRef, wrapperRef, isWindowOpen])

    return (
        <dialog ref={wrapperRef} className={styles.wrapper} open={isWindowOpen}>
            <section ref={modalRef} className={`${styles.wrapper__content} ${wrapperClassName}`}>
                {children}
            </section>
        </dialog>
    )
}