import {useEffect, useState} from "react";
import {EntitiesPageHeader} from "../../../components/search-bar/EntitiesPageHeader";
import {useDispatch, useSelector} from "react-redux";
import {workoutsSelector} from "../../../store/selectors/workouts-selector";
import {WorkoutsListTable} from "./WorkoutsListTable";
import {PaginationPanel} from "../../../components/pagination-panel/PaginationPanel";
import {getWorkoutsList} from "../../../store/operations/workouts-operations";
import {useNavigate} from "react-router-dom";
import {withAllowedQuery} from "../../../hocs/withAllowedQuery";

export const WorkoutsListPage = withAllowedQuery(['text'])(({queryParams, setQueryParams}) => {
    const [searchText, setSearchText] = useState(queryParams.text || '')
    const {workoutsList, isLoading, totalCount} = useSelector(workoutsSelector)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const onPageChange = (page) => {
        dispatch(getWorkoutsList(page * 20, 20, null, null, searchText))
    }

    useEffect(() => {
        dispatch(getWorkoutsList(0, 20, null, null, searchText))
    }, [queryParams])

    const searchButtonHandler = (event) => {
        event.preventDefault()
        setQueryParams({
            text: searchText
        })
    }

    return (
        <>
            <EntitiesPageHeader input={{
                disabled: false,
                value: searchText,
                onChange: setSearchText
            }} placeholder={'Type for search workouts'} buttons={[
                {title: 'Search', clickHandler: searchButtonHandler, type: 'submit'},
                {title: 'Create', clickHandler: () => navigate('/dashboard/workouts/create', {replace: true})}
            ]}/>
            <section>
                <WorkoutsListTable workoutsList={workoutsList}/>
            </section>
            <PaginationPanel onPageChange={onPageChange} initPage={0} totalPages={Math.ceil(totalCount / 20)}/>
        </>
    )
})
