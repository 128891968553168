import {clearChallengesList, saveChallengesList, setChallengesLoadingStatus} from "../actions/challenges-actions";
import ChallengesAPI from "../../api/ChallengesAPI";

export const getChallengesList = (offset, limit, text) => {
    return dispatch => {
        dispatch(setChallengesLoadingStatus(true))

        dispatch(clearChallengesList())

        new ChallengesAPI().getAllChallenges(offset, limit, text).then(({data}) => {
            dispatch(saveChallengesList(data.items, data.count))
        }).catch(error => {
            console.log(error)
        }).finally(() => dispatch(setChallengesLoadingStatus(false)))
    }
}
