import {useDispatch, useSelector} from "react-redux";
import {getUsersList} from "../../../store/operations/users-operations";
import {usersSelector} from "../../../store/selectors/users-selector";
import {EntitiesPageHeader} from "../../../components/search-bar/EntitiesPageHeader";
import {PaginationPanel} from "../../../components/pagination-panel/PaginationPanel";
import {UsersListTable} from "./UsersListTable";
import {withAllowedQuery} from "../../../hocs/withAllowedQuery";
import {useEffect, useState} from "react";

export const UsersListPage = withAllowedQuery(['text'])(({queryParams, setQueryParams}) => {
    const [searchText, setSearchText] = useState(queryParams.text || '')
    const {usersList, totalCount, isLoading} = useSelector(usersSelector)
    const dispatch = useDispatch()

    const onPageChange = (page) => {
        dispatch(getUsersList(page * 20, 20, searchText))
    }

    useEffect(() => {
        dispatch(getUsersList(0, 20, searchText))
    }, [queryParams])

    const searchButtonHandler = (event) => {
        event.preventDefault()
        setQueryParams({
            text: searchText
        })
    }

    return (
        <>
            <EntitiesPageHeader input={{
                value: searchText,
                onChange: setSearchText,
                disabled: false
            }} buttons={[{title: 'Search', clickHandler: searchButtonHandler, type: 'submit'}]} placeholder={'Input user name here'}/>
            <section>
                <UsersListTable usersList={usersList}/>
                <PaginationPanel onPageChange={onPageChange} initPage={0} totalPages={Math.ceil(totalCount / 20)}/>
            </section>
        </>
    )
})
