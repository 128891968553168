import {
    CLEAR_CHALLENGES_LIST,
    SAVE_CHALLENGES_LIST,
    SET_CHALLENGES_LOADING_STATUS
} from "../action-types/challenges-action.types";

export const saveChallengesList = (challengesList, totalCount) => {
    return {type: SAVE_CHALLENGES_LIST, payload: {challengesList, totalCount}}
}

export const clearChallengesList = () => {
    return {type: CLEAR_CHALLENGES_LIST}
}

export const setChallengesLoadingStatus = (loadingStatus) => {
    return {type: SET_CHALLENGES_LOADING_STATUS, payload: loadingStatus}
}