import {BaseAPI} from "./BaseAPI";

class PasswordAPI extends BaseAPI{
    constructor() {
        super('forgot-password', false)
    }


    sendForgotPasswordRequest = async email => {
        return await this.service.post('/', {email})
    }

    sendCheckCodeRequest = async (code, id) => {
        return await this.service.post(`/${id}/check-code`, {code})
    }

    sendResetPasswordRequest = async (id, code, password) => {
        return await this.service.post(`/${id}/reset`, {code, password})
    }
}

export default PasswordAPI
