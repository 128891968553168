import {instructorsActionTypes} from "../action-types/instructors-action.types";

export const _setIsInstructorsLoading = (isLoading) => {
    return {type: instructorsActionTypes.SET_IS_LOADING, payload: isLoading}
}

export const _setInstructorsList = (instructorsList, totalCount) => {
    return {type: instructorsActionTypes.SET_LIST, payload: {instructorsList, totalCount}}
}

export const _clearInstructorsList = () => {
    return {type: instructorsActionTypes.CLEAR_LIST}
}
