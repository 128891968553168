import {InstructorsListTableRow} from "./InstructorsListTableRow";

export const InstructorsListTable = ({instructorsList, onInstructorClick}) => {
    return instructorsList?.length ? (
        <table>
            <thead>
            <tr>
                <td>Instructor Name</td>
            </tr>
            </thead>
            <tbody>
            {instructorsList?.map(data => <InstructorsListTableRow onInstructorClick={onInstructorClick} data={data}/>)}
            </tbody>
        </table>
    ) : <h2 id={'nothing-found'}>Nothing found....</h2>
}
