import {BaseAPI} from "./BaseAPI";

class LogsAPI extends BaseAPI {
    constructor() {
        super('logs')
    }

    list = async () => {
        return this.service.get('')
    }

    get = async (name) => {
        return this.service.get(`/${name}`)
    }
}

export default LogsAPI