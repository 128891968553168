import {ADD_USERS_TO_LIST, CLEAR_USERS_LIST, SET_USERS_LOADING_STATUS} from "../action-types/users-action.types";

export const addUsersToList = (usersList, totalCount) => {
    return {type: ADD_USERS_TO_LIST, payload: {usersList, totalCount}}
}

export const setUsersLoadingStatus = (loadingStatus) => {
    return {type: SET_USERS_LOADING_STATUS, payload: loadingStatus}
}

export const clearUsersList = () => {
    return {type: CLEAR_USERS_LIST}
}