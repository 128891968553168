import {
  CLEAR_WORKOUTS_LIST,
  SAVE_WORKOUTS_LIST,
  SET_WORKOUTS_LOADING_STATUS,
  SET_WORKOUTS_LIST_ALL,
} from "../action-types/workouts-action.types";

export const saveWorkoutsList = (workoutsList, totalCount) => {
  return { type: SAVE_WORKOUTS_LIST, payload: { workoutsList, totalCount } };
};

export const clearWorkoutsList = () => {
  return { type: CLEAR_WORKOUTS_LIST };
};

export const setWorkoutsLoadingStatus = (loadingStatus) => {
  return { type: SET_WORKOUTS_LOADING_STATUS, payload: loadingStatus };
};

export const saveWorkoutsListForAll = (workoutsList, totalCount) => {
  return { type: SET_WORKOUTS_LIST_ALL, payload: { workoutsList, totalCount } };
};
