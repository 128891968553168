import {useMemo} from "react";
import styles from './image-input.module.scss'

import noImage from '../../assets/image/no-image.png'

export const ImageInput = ({className, options = {}, value, withPreview = false}) => {
    return (
        <label className={`${styles.wrapper} ${className ? className : ''}`}>
            {withPreview && <img src={value ? URL.createObjectURL(value) : noImage} alt={'image_input'}/>}
            <input {...options} type={'file'} accept={'image/*'}/>
        </label>
    )
}
