import {getLocalAccessToken} from "../services/token.service";
import {BaseAPI} from "./BaseAPI";

class AuthAPI extends BaseAPI{
    constructor() {
        super('auth', false)
    }

    sendLoginRequest = async credentials => {
        return await this.service.post('sign-in/admin', credentials);
    }

    sendUpdateTokenRequest = async refreshToken => {
        return await this.service.post('update-token', refreshToken)
    }

    sendLogOutRequest = async () => {
        return await this.service.post('logout', {}, {
            headers: {
                "Authorization": 'Bearer ' + getLocalAccessToken()
            }
        })
    }
}

export default AuthAPI
