import {BaseAPI} from "./BaseAPI";

class SettingsAPI extends BaseAPI{
    constructor() {
        super('default-setting')
    }

    getDefaultSettings = async () => {
        return this.service.get('')
    }

    setDefaultSettings = async (newSettings) => {
        return this.service.post('', newSettings)
    }
}

export default SettingsAPI