import {
  CLEAR_WORKOUTS_LIST,
  SAVE_WORKOUTS_LIST,
  SET_WORKOUTS_LIST_ALL,
  SET_WORKOUTS_LOADING_STATUS,
} from "../action-types/workouts-action.types";

const initState = {
  workoutsList: [],
  totalCount: 0,
  workoutsListForAll: [],
  isLoading: false,
};

export const workoutsReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case SAVE_WORKOUTS_LIST:
      return {
        ...state,
        workoutsList: payload.workoutsList,
        totalCount: payload.totalCount,
      };
    case CLEAR_WORKOUTS_LIST:
      return { ...state, workoutsList: [], totalCount: 0 };
    case SET_WORKOUTS_LIST_ALL:
      return {
        ...state,
        workoutsListForAll: payload.workoutsList,
        totalCount: payload.totalCount,
      };
    case SET_WORKOUTS_LOADING_STATUS:
      return { ...state, isLoading: payload };
    default:
      return state;
  }
};
