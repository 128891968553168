import { useEffect, useRef, useState } from "react";
import styles from "./select.module.scss";
import crossIcon from '../../../assets/image/cross-icon.svg'
import { useOutsideClick } from "../../../hooks/useOutsideClick";

export default ({ options, value, onSelect, name, selectClassName, icon, selectedClassName, menuClassName, placeholder, required = false ,id}) => {
    const wrapperRef = useRef(null)
    const [selectedItem, setSelectedItem] = useState(value)
    const [isMenuOpen, setIsMenuOpen] = useState(false)

    useOutsideClick(wrapperRef, () => setIsMenuOpen(false))

    const optionClickHandler = (option) => {
        setSelectedItem(option)
        setIsMenuOpen(false)
    }

    useEffect(() => {
        onSelect && onSelect(name, selectedItem?.title,id)
    }, [selectedItem])

    return (
        <div ref={wrapperRef} className={`${styles.multiselect} ${selectClassName}`}>

            {!selectedItem && <p className={styles.multiselect_placeholder}>{placeholder}</p>}

            {icon && <img src={icon} className={styles.multiselect_icon} alt={icon.name} />}

            <div onClick={setIsMenuOpen.bind(null, !isMenuOpen)}
                className={`${styles.multiselect__selected} ${selectedClassName}`}>
                <input style={{
                    opacity: selectedItem ? 1 : 0,
                }} value={value?.title == null ? selectedItem?.title : value?.title} required={required} />
            </div>

            {selectedItem && <img src={crossIcon} className={styles.multiselect_clear} alt={'select_cross'}
                onClick={setSelectedItem.bind(null, null)} />}

            {isMenuOpen && (
                <ul className={`${styles.multiselect__menu} ${menuClassName}`}>
                    {options.map(option => <li key={'option' + option._id}
                        onClick={optionClickHandler.bind(null, option)}
                        className={styles.multiselect__menu_option}>{option.title}</li>)}
                </ul>
            )}

        </div>
    )
}
