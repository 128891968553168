import { useSearchParams } from 'react-router-dom';
import { useMemo } from 'react';
import { useEffect, useState } from 'react';

export const withAllowedQuery = allowedParams => {
    return Component => {
        return props => {
            const [_urlQuery, _setUrlQuery] = useSearchParams();
            const [readyToRender, setReadyToRender] = useState(false);

            const queryParams = useMemo(() => Object.fromEntries(_urlQuery), [_urlQuery]);

            useEffect(() => {
                setReadyToRender(false);

                let _params = { ...queryParams };

                Object.keys(_params).forEach(_p => !allowedParams.includes(_p) && delete _params[_p] && _setUrlQuery(_params));

                setReadyToRender(true);
            }, [queryParams]);

            return readyToRender ? <Component queryParams={queryParams} setQueryParams={_setUrlQuery} {...props} /> : null;
        };
    };
};
