import { BaseAPI } from "./BaseAPI";

class WorkoutsAPI extends BaseAPI {
  constructor() {
    super("workout");
  }

  createWorkout = async (workoutData) => {
    return await this.service.post("", workoutData);
  };

  updateWorkout = async (workoutData, id) => {
    return await this.service.put(`/${id}`, workoutData);
  };

  getById = async (id) => {
    return await this.service.get(`/${id}`);
  };

  updateWorkoutVideo = async (workoutVideoData, id) => {
    return await this.service.put(`/${id}/video`, workoutVideoData);
  };

  deleteWorkout = async (id) => {
    return await this.service.delete(`/${id}`);
  };

  getAllWorkouts = async ({
    bodyPart,
    difficult,
    offset = 0,
    limit = 20,
    text,
  }) => {
    return await this.service.get("/all", {
      params: {
        bodyPart,
        difficult,
        offset,
        limit,
        text,
      },
    });
  };
  getAllWorkoutsWithoutPagination = async () => {
    return await this.service.get("/all_workouts");
  };

  updateInformation = async (data, id) => {
    const jsonToFormData = (data) => {
      const formData = new FormData();
      formData.append("customThumbnail", data?.customThumbnail);
      formData.append("difficulty", data?.difficulty);
      formData.append("priority", data?.priority);
      formData.append("workoutStyle", data?.workoutStyle);
      formData.append("parts", JSON.stringify(data?.parts));
      return formData;
    };
    return await this.service.put(
      `/${id}/set-custom-thumbnail`,
      jsonToFormData(data)
    );
  };

  resetAverageHR = async (id, partIndex) => {
    return await this.service.put(`/${id}/parts/${partIndex}/reset-average-hr`);
  };

  resetWorkoutDifficulty = async (id) => {
    return await this.service.put(`/${id}/reset-difficulty`);
  };
}

export default WorkoutsAPI;
