import styles from './challenge-creation.module.scss'
import {Input} from "../../../components/input/Input";
import {Textarea} from "../../../components/textarea/Textarea";
import {useEffect, useState} from "react";
import ChallengesAPI from "../../../api/ChallengesAPI";
import {Button} from "../../../components/button/Button";
import {useDispatch, useSelector} from "react-redux";
import {workoutsSelector} from "../../../store/selectors/workouts-selector";
import {useNavigate} from "react-router-dom";
import {getWorkoutsList} from "../../../store/operations/workouts-operations";
import {PaginationPanel} from "../../../components/pagination-panel/PaginationPanel";
import defaultThumbnail from '../../../assets/image/no-thumbnail-image.jpg'
import {useAlert} from "../../../hooks/useAlert";
import addIcon from '../../../assets/image/add_icon.png'
import dayOffIcon from '../../../assets/image/day_off_icon.png'
import {ImageInput} from "../../../components/image-input/ImageInput";

export const ChallengeCreationPage = props => {
    const [challengeData, setChallengeData] = useState({
        title: '',
        description: '',
        difficulty: 1,
        isPrimary: true,
        workouts: []
    })
    const [thumbnail, setThumbnail] = useState(null)
    const [challengeLength, setChallengeLength] = useState(30)

    const {workoutsList, isLoading, totalCount} = useSelector(workoutsSelector)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [successAlert, errorAlert] = useAlert()

    const onPageChange = (page) => {
        dispatch(getWorkoutsList(page * 20, 20))
    }

    useEffect(() => {
        if (challengeLength < challengeData.workouts.length) {

            let newWorkoutsList = challengeData.workouts

            newWorkoutsList.splice(challengeLength - 1, challengeData.workouts.length - challengeLength)

            setChallengeData({
                ...challengeData,
                workouts: newWorkoutsList
            })
        }
    }, [challengeLength, challengeData.workouts.length])

    const challengeLengthHandler = (event) => {
        setChallengeLength(event.target.value)
    }

    const thumbnailHandler = ({target: {files: [_thumbnail]}}) => {
        setThumbnail(_thumbnail)
    }


    const addDayOffClickHandler = () => {
        let newWorkoutsList = challengeData.workouts

        const index = newWorkoutsList.findIndex(w => w?.workoutId === undefined)

        if (index === -1) newWorkoutsList.push({
            type: 'DAY_OFF',
            workoutId: `${challengeData.workouts.length}__day_off`
        })
        else newWorkoutsList.splice(index, 1, {type: 'DAY_OFF', workoutId: `${index}__day_off`})

        setChallengeData({
            ...challengeData,
            workouts: newWorkoutsList
        })
    }

    const removeDayOffClickHandler = (index) => {
        let newWorkoutsList = challengeData.workouts

        const _index = newWorkoutsList.findIndex(w => {
            return w?.workoutId.split('__')[0] === `${index}`
        })

        newWorkoutsList.splice(_index, 1, null)

        setChallengeData({
            ...challengeData,
            workouts: newWorkoutsList
        })
    }

    const addWorkoutHandler = (_id, video, title) => {
        let newWorkoutsList = challengeData.workouts

        const index = newWorkoutsList.findIndex(w => w?.workoutId === undefined)

        if (index === -1) newWorkoutsList.push({
            type: "CLASS_DAY",
            workoutId: _id,
            tmpId: Date.now(),
            video,
            title
        })
        else newWorkoutsList.splice(index, 1, {
            type: "CLASS_DAY",
            workoutId: _id,
            tmpId: Date.now(),
            video,
            title
        })

        setChallengeData({
            ...challengeData,
            workouts: newWorkoutsList
        })
    }

    const removeWorkoutHandler = (_id) => {
        let newWorkoutsList = challengeData.workouts

        const index = newWorkoutsList.findIndex(w => w?.tmpId === _id)

        newWorkoutsList.splice(index, 1, null)

        setChallengeData({
            ...challengeData,
            workouts: newWorkoutsList
        })
    }

    const onChange = ({target}) => {
        let {name, value} = target

        if (name === 'challengeLength') return;

        if (name === 'thumbnail') return;

        if (name === 'isPrimary') value = target.checked

        setChallengeData({
            ...challengeData,
            [name]: value
        })
    }

    const submitHandler = (event) => {
        event.preventDefault()
        new ChallengesAPI().createChallenge({
            ...challengeData,
            workouts: challengeData.workouts.map(w => {
                return w?.type === 'DAY_OFF' ? null : w?.type === 'CLASS_DAY' ? w.workoutId : null
            })
        }).then(response => {
            successAlert('Challenge was created!')

            const formData = new FormData()

            formData.append('thumbnail', thumbnail)

            new ChallengesAPI().updateThumbnail(response.data._id, formData).then(response => {
                navigate('/dashboard/challenges')
            }).catch(errorAlert)
        }).catch(errorAlert)
    }

    console.log(challengeLength, challengeData?.workouts?.length)

    return (
        <form className={styles.create} onSubmit={submitHandler} onChange={onChange}>
            <section className={styles.create__basic}>
                <div className={styles.create__basic__thumbnail}>
                    <ImageInput options={{
                        name: 'thumbnail',
                        onChange: thumbnailHandler
                    }} value={thumbnail} withPreview={true}/>
                </div>
                <div className={styles.create__basic__text}>
                    <fieldset>
                        <label htmlFor={'title'}>Title:</label>
                        <Input name={'title'} value={challengeData.title}/>
                    </fieldset>
                    <fieldset>
                        <label htmlFor={'description'}>Description: </label>
                        <Textarea name={'description'} value={challengeData.description}/>
                    </fieldset>
                </div>
            </section>
            <fieldset>
                <label htmlFor={'difficulty'}>Difficulty ({challengeData.difficulty})</label>
                <Input name={'difficulty'} value={challengeData.difficulty} type={'range'} min={1} max={10} step={1}/>
            </fieldset>
            <fieldset>
                <label htmlFor={'isPrimary'}>Is Primary</label>
                <Input name={'isPrimary'} type={'checkbox'} checked={challengeData.isPrimary}/>
            </fieldset>
            <fieldset>
                <label htmlFor={'challengeLength'}>Challenge length ({challengeLength} days)</label>
                <Input name={'challengeLength'} onChange={challengeLengthHandler} value={challengeLength} type={'range'}
                       min={1} max={60} step={1}/>
            </fieldset>
            <section className={styles.create__workouts}>
                <div className={styles.create__workouts__wrapper}>
                    <p>Select workouts for this challenge:</p>
                    {workoutsList.map(({video, _id, title}) => {
                        return (
                            <div key={_id} onClick={addWorkoutHandler.bind(null, _id, video, title)}
                                 className={styles.create__workouts__wrapper__tile}>
                                <img src={video?.thumbnail || defaultThumbnail} alt={_id + '_thumbnail'}/>
                                <small>{title}</small>
                            </div>
                        )
                    })}
                    <PaginationPanel className={styles.create__workouts__wrapper__pagination}
                                     onPageChange={onPageChange} initPage={0} totalPages={Math.ceil(totalCount / 20)}/>
                </div>
                <hr/>
                <div className={styles.create__workouts__wrapper}>
                    <p>Selected workouts for this challenge:</p>
                    {challengeData.workouts.map((w, index) => {
                        if (w?.type === 'CLASS_DAY') {
                            return (
                                <div key={w.tmpId} onClick={removeWorkoutHandler.bind(null, w.tmpId)}
                                     className={`${styles.create__workouts__wrapper__tile}`}>
                                    <small
                                        className={styles.create__workouts__wrapper__chosen_title}>Day {index + 1} / {challengeLength}</small>
                                    <img src={w.video?.thumbnail || defaultThumbnail} alt={w.workoutId + '_thumbnail'}/>
                                    <small>{w.title}</small>
                                </div>
                            )
                        } else if (w?.type === 'DAY_OFF') {
                            return (
                                <div key={`${index}_day_off`} onClick={removeDayOffClickHandler.bind(null, index)}
                                     className={`${styles.create__workouts__wrapper__tile} ${styles.create__workouts__wrapper__tile__day_off}`}>
                                    <small
                                        className={styles.create__workouts__wrapper__chosen_title}>Day {index + 1} / {challengeLength}</small>
                                    <img src={dayOffIcon} alt={index + '_thumbnail'}/>
                                    <small>Day Off</small>
                                </div>
                            )
                        }

                        return null
                    })}
                    <div onClick={addDayOffClickHandler}
                         className={`${styles.create__workouts__wrapper__tile} ${styles.create__workouts__wrapper__tile__day_off}`}>
                        <img src={addIcon} alt={'add day off'}/>
                        <small>Add Day off</small>
                    </div>
                </div>
            </section>
            <section className={styles.create__buttons}>
                <Button isGrey={true} onClick={navigate.bind(null, '/dashboard/challenges', {replace: true})}
                        type={'button'} title={'Cancel'}/>
                <Button disabled={+challengeLength !== challengeData?.workouts?.length} type={'submit'} title={'Create'}/>
            </section>
        </form>
    )
}
