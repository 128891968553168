import WorkoutsAPI from "../../api/WorkoutsAPI";
import {
  saveWorkoutsList,
  setWorkoutsLoadingStatus,
  saveWorkoutsListForAll,
} from "../actions/workouts-actions";

export const getWorkoutsList = (
  offset = 0,
  limit = 20,
  bodyPart = null,
  difficult = null,
  text
) => {
  return (dispatch) => {
    dispatch(setWorkoutsLoadingStatus(true));

    new WorkoutsAPI()
      .getAllWorkouts({ bodyPart, difficult, offset, limit, text })
      .then((res) => {
        dispatch(saveWorkoutsList(res.data.items, res.data.count));
      })
      .catch((error) => console.log(error))
      .finally(() => {
        dispatch(setWorkoutsLoadingStatus(false));
      });
  };
};
export const getWorkoutsListForAll = () => {
  return (dispatch) => {
    dispatch(setWorkoutsLoadingStatus(true));

    new WorkoutsAPI()
      .getAllWorkoutsWithoutPagination()
      .then((res) => {
        dispatch(saveWorkoutsListForAll(res.data.items, res.data.count));
      })
      .catch((error) => console.log(error))
      .finally(() => {
        dispatch(setWorkoutsLoadingStatus(false));
      });
  };
};
