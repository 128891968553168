import ReactDOM from "react-dom";

import {useEffect, useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import {alertsSelector} from "../../store/selectors/alerts-selector";

import styles from './alerts-wrapper.module.scss'
import {removeAlertContent, setIsAlertVisible} from "../../store/actions/alerts-actions";

import errorIcon from '../../assets/image/error-icon.png'
import successIcon from '../../assets/image/success-icon.png'
import crossIcon from '../../assets/image/cross-icon.svg'

export const AlertsWrapper = props => {
    const base = useMemo(() => document.createElement('div'), [])

    const dispatch = useDispatch()

    const {alerts} = useSelector(alertsSelector)

    useEffect(() => {
        base.className = styles.alerts
        document.body.appendChild(base)

        return () => {
            document.body.removeChild(base)
        }
    }, [])

    useEffect(() => {
        if (alerts.length === 0) {
            dispatch(setIsAlertVisible(false))
        }
    }, [alerts])

    return ReactDOM.createPortal(
        <>
            {alerts.map(({alertId, Content, isError}) => {
                return (
                    <div key={alertId}
                         onAnimationEnd={e => {
                             if (e.nativeEvent.animationName === styles.moveUp) dispatch(removeAlertContent(alertId))
                             if (e.nativeEvent.animationName === styles.moveLeft) {
                                 setTimeout(() => {
                                     e.target.className = `${e.target.className} ${styles.move_up}`
                                 }, 3000)
                             }
                         }}
                         className={`${styles.alerts__tile} ${styles.move_left}`}>
                        <div className={styles.alerts__content__wrapper}>
                            <img className={styles.alerts__content__wrapper__type_img} src={isError ? errorIcon : successIcon} alt={'alert_icon'}/>
                            <div className={styles.alerts__content__wrapper__content}>
                                <Content/>
                            </div>
                            <img onClick={() => dispatch(removeAlertContent(alertId))} className={styles.alerts__content__wrapper__tile_close} src={crossIcon} alt={'alert_cross_icon'}/>
                        </div>
                    </div>
                )
            })}
        </>,
        base
    )
}