import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {instructorsSelector} from "../../../store/selectors/instructorsSelector";
import {EntitiesPageHeader} from "../../../components/search-bar/EntitiesPageHeader";
import {InstructorsListTable} from "./InstructorsListTable";
import {PaginationPanel} from "../../../components/pagination-panel/PaginationPanel";
import {getInstructorsList} from "../../../store/operations/instructors-operations";
import {ModalWindow} from "../../../components/modal-window/ModalWindow";
import {EditInstructorModalWindow} from "../modals/EditInstructorModalWindow";

export const InstructorsListPage = () => {
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [modalContent, setModalContent] = useState(null)
    const {totalCount, instructorsList} = useSelector(instructorsSelector)

    const dispatch = useDispatch()

    const onPageChange = (page) => {
        dispatch(getInstructorsList(page * 20, 20))
    }

    const createButtonHandler = () => {
        setModalContent(<EditInstructorModalWindow/>)
        setIsModalOpen(true)
    }

    const onInstructorClickHandler = ({_id, name}) => {
        setModalContent(<EditInstructorModalWindow _id={_id} name={name}/>)
        setIsModalOpen(true)
    }


    return (
        <>
            <ModalWindow isWindowOpen={isModalOpen} setIsWindowOpen={setIsModalOpen}>
                {modalContent}
            </ModalWindow>
            <EntitiesPageHeader buttons={[{title: 'Create', clickHandler: createButtonHandler}]}/>
            <section>
                <InstructorsListTable onInstructorClick={onInstructorClickHandler} instructorsList={instructorsList}/>
                <PaginationPanel onPageChange={onPageChange} initPage={0} totalPages={Math.ceil(totalCount / 20)}/>
            </section>
        </>
    )
}
