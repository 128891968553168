export const bodyParts = [
    {title: "FULL_BODY"},
    {title: "LOWER_BODY"},
    {title: "UPPER_BODY"},
    {title: "ABS"}
]

export const workoutStyles = [
    {title: 'Run'},
    {title: 'Strength'},
    {title: 'HIIT'},
    {title: 'Barre'},
    {title: 'Cycling'},
    {title: 'Yoga'},
    {title: 'Pilates'}
]
