import { useEffect, useState } from "react";
import _ from "lodash";

import BillingAPI from "../../api/BillingAPI";
import { useAlert } from "../../hooks/useAlert";
import { Input } from "../../components/input/Input";
import { Button } from "../../components/button/Button";

import styles from "./billing-page.module.scss";
import { Loader } from "../../components/loader/loader";

export const Stripe = () => {
  const [settings, setSettings] = useState(null);
  const [newSettings, setNewSettings] = useState(null);
  const [successAlert, errorAlert] = useAlert();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    new BillingAPI()
      .getBillingSettings()
      .then(({ data }) => {
        setSettings(data);
        setNewSettings(data);
        setLoaded(true);
      })
      .catch(errorAlert);
  }, []);

  const fieldHandler = ({ target: { name, value } }) => {
    setNewSettings({
      ...newSettings,
      [name]: value,
    });
  };

  const trialDaysHandler = ({ target: { name, value } }) => {
    let newPlansArray = [...newSettings?.prices];

    const index = newPlansArray.findIndex((p) => p.priceId === name);

    if (index !== -1) {
      newPlansArray.splice(index, 1, {
        ...newSettings.prices[index],
        trialDays: +value,
      });

      setNewSettings({ ...newSettings, prices: newPlansArray });
    }
  };

  const priceKeyHandler = ({ target: { name, value } }) => {
    let newPlansArray = [...newSettings?.prices];

    const index = newPlansArray.findIndex((p) => p.priceId === name);

    if (index !== -1) {
      newPlansArray.splice(index, 1, {
        ...newSettings.prices[index],
        priceId: value,
      });

      setNewSettings({ ...newSettings, prices: newPlansArray });
    }
  };

  const resetHandler = (name) => {
    setNewSettings({
      ...newSettings,
      [name]: settings[name],
    });
  };

  const resetPriceData = (priceId) => {
    let newPlansArray = [...newSettings?.prices];

    const index = newPlansArray.findIndex((p) => p.priceId === priceId);

    if (index !== -1) {
      newPlansArray.splice(index, 1, { ...settings.prices[index] });

      setNewSettings({ ...newSettings, prices: newPlansArray });
    }
  };

  const updatePriceHandler = ({ id, newPriceId, trialDays }) => {
    new BillingAPI()
      .updatePrice({ id, trialDays, newPriceId })
      .then((response) => {
        successAlert("Price has been updated!");
        setSettings(newSettings);
      })
      .catch(errorAlert);
  };

  const updatePublicKeyHandler = () => {
    new BillingAPI()
      .updatePublicKey(newSettings.publicKey)
      .then((response) => {
        successAlert("Public key has been updated");
        setSettings({ ...settings, publicKey: newSettings.publicKey });
      })
      .catch(errorAlert);
  };

  const updateSecretKeyHandler = () => {
    new BillingAPI()
      .updateSecretKey(newSettings.secretKey)
      .then((response) => {
        successAlert("Secret key has been updated");
        setSettings({ ...settings, secretKey: newSettings.secretKey });
      })
      .catch(errorAlert);
  };
  const freeSubHandler = (event) => {
    switch (event.target.name) {
      case "checkbox":
        setNewSettings({
          ...newSettings,
          freeSubscribe: {
            ...newSettings.freeSubscribe,
            available: event.target.checked,
          },
        });
        break;
      case "card":
        setNewSettings({
          ...newSettings,
          freeSubscribe: {
            ...newSettings.freeSubscribe,
            payment: {
              ...newSettings.freeSubscribe.payment,
              card: event.target.value,
            },
          },
        });
        break;
      case "year":
        setNewSettings({
          ...newSettings,
          freeSubscribe: {
            ...newSettings.freeSubscribe,
            payment: {
              ...newSettings.freeSubscribe.payment,
              expireYear: event.target.value,
            },
          },
        });
        break;
      case "month":
        setNewSettings({
          ...newSettings,
          freeSubscribe: {
            ...newSettings.freeSubscribe,
            payment: {
              ...newSettings.freeSubscribe.payment,
              expireMonth: event.target.value,
            },
          },
        });
        break;
      case "cvc":
        setNewSettings({
          ...newSettings,
          freeSubscribe: {
            ...newSettings.freeSubscribe,
            payment: {
              ...newSettings.freeSubscribe.payment,
              cvc: event.target.value,
            },
          },
        });
        break;
      case "priceid":
        setNewSettings({
          ...newSettings,
          freeSubscribe: {
            ...newSettings.freeSubscribe,
            priceId: event.target.value,
          },
        });
        break;
      case "trialDays":
        setNewSettings({
          ...newSettings,
          freeSubscribe: {
            ...newSettings.freeSubscribe,
            trialDays: event.target.value,
          },
        });

        break;
    }
  };

  const saveSubscriptionData = () => {
    new BillingAPI()
      .updateFreeSubscribeData(newSettings.freeSubscribe)
      .then(() => {
        window.location.reload();
      })
      .catch(errorAlert);
  };

  if (!loaded) return <Loader />;

  return (
    <>
      <h1 className={styles.billing__header}>Stripe settings</h1>
      <fieldset className={styles.billing__fieldset}>
        <legend>Secret key</legend>

        <Input
          onChange={fieldHandler}
          name={"secretKey"}
          className={styles.billing__fieldset_input}
          value={newSettings?.secretKey}
        />

        <Button
          title={"Reset"}
          onClick={resetHandler.bind(null, "secretKey")}
          isGrey={true}
          isVisible={newSettings?.secretKey !== settings?.secretKey}
        />

        <Button
          onClick={updateSecretKeyHandler}
          title={"Apply"}
          isVisible={newSettings?.secretKey !== settings?.secretKey}
        />
      </fieldset>

      <fieldset className={styles.billing__fieldset}>
        <legend>Public key</legend>

        <Input
          onChange={fieldHandler}
          name={"publicKey"}
          className={styles.billing__fieldset_input}
          value={newSettings?.publicKey}
        />

        <Button
          title={"Reset"}
          onClick={resetHandler.bind(null, "publicKey")}
          isGrey={true}
          isVisible={newSettings?.publicKey !== settings?.publicKey}
        />

        <Button
          onClick={updatePublicKeyHandler}
          title={"Apply"}
          isVisible={newSettings?.publicKey !== settings?.publicKey}
        />
      </fieldset>

      <section className={styles.billing__prices}>
        {newSettings?.prices.map((priceData, index) => {
          const showReset = !_.isEqual(priceData, settings.prices[index]);

          return (
            <div
              className={styles.billing__prices__plan}
              id={priceData.priceId}
            >
              <Input
                name={priceData.priceId}
                onChange={priceKeyHandler}
                className={styles.billing__prices__plan_key}
                value={priceData.priceId}
              />
              <p>
                <span>Type: </span> {priceData.type}
              </p>
              <p className={styles.billing__prices__plan__trials}>
                <span>Trial Days: </span>
                <Input
                  name={priceData.priceId}
                  onChange={trialDaysHandler}
                  value={priceData.trialDays}
                />
                <Button
                  onClick={resetPriceData.bind(null, priceData.priceId)}
                  title={"Reset"}
                  isGrey={true}
                  isVisible={showReset}
                />
                <Button
                  onClick={updatePriceHandler.bind(null, {
                    id: settings.prices[index].priceId,
                    trialDays: priceData.trialDays,
                    newPriceId: priceData.priceId,
                  })}
                  title={"Apply"}
                  isVisible={showReset}
                />
              </p>
            </div>
          );
        })}
      </section>

      <h2 className={styles.billing__header}>Free subscribe settings</h2>

      {newSettings?.freeSubscribe && (
        <section
          onChange={freeSubHandler}
          className={styles.billing__free_subs}
        >
          <div className={styles.billing__free_subs__checkbox_wrapper}>
            <p>Is free subscribe available ?</p>
            <Input
              name={"checkbox"}
              checked={newSettings.freeSubscribe.available}
              type={"checkbox"}
            />
            <p className={styles.billing__prices__plan__trials}>
              <span>Trial Days: </span>
              <Input
                name={"trialDays"}
                value={newSettings?.freeSubscribe?.trialDays}
              />
            </p>
          </div>
          <div className={styles.billing__free_subs__card_data}>
            <p>Auto payment card data:</p>
            <Input
              name={"card"}
              value={newSettings?.freeSubscribe?.payment?.card}
              placeholder={"Card number"}
              className={styles.billing__free_subs__card_data__number}
            />
            <Input
              name={"year"}
              value={newSettings?.freeSubscribe?.payment?.expireYear}
              placeholder={"Expiry year"}
              className={styles.billing__free_subs__card_data__date}
            />
            <Input
              name={"month"}
              value={newSettings?.freeSubscribe?.payment?.expireMonth}
              placeholder={"Expiry month"}
              className={styles.billing__free_subs__card_data__date}
            />
            <Input
              name={"cvc"}
              value={newSettings?.freeSubscribe?.payment?.cvc}
              placeholder={"CVC"}
              className={styles.billing__free_subs__card_data__cvv}
            />
          </div>
          <div className={styles.billing__free_subs__priceid}>
            <p>Stripe price id for free subscription</p>
            <Input
              value={newSettings.freeSubscribe.priceId}
              name={"priceid"}
              className={styles.billing__free_subs__priceid__input}
              placeholder={"Price ID"}
            />
          </div>

          <Button onClick={saveSubscriptionData}>
            Save free subscription data
          </Button>
        </section>
      )}
    </>
  );
};
