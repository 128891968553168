import {ChallengesListTableRow} from "./ChallengesListTableRow";

export const ChallengesListTable = ({challengesList, e, d}) => {
    return challengesList?.length ? (
        <table>
            <thead>
            <tr>
                <td>Title</td>
                <td colSpan={2}>Description</td>
            </tr>
            </thead>
            <tbody>
            {challengesList?.map(challenge => <ChallengesListTableRow {...challenge} e={e} d={d}/>)}
            </tbody>
        </table>
    ) : <h2 id={'nothing-found'}>Nothing found....</h2>
}
