import {ADD_ALERT_CONTENT, REMOVE_ALERT_CONTENT, SET_IS_ALERT_VISIBLE} from "../action-types/alerts-action.types";

export const addAlertContent = ({Content, isError}) => {
    return {type: ADD_ALERT_CONTENT, payload: {Content, isError, alertId: Date.now()}}
}

export const setIsAlertVisible = (isVisible) => {
    return {type: SET_IS_ALERT_VISIBLE, payload: isVisible}
}

export const removeAlertContent = alertId => {
    return {type: REMOVE_ALERT_CONTENT, payload: alertId}
}