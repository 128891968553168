import {useState} from "react";
import EquipmentsAPI from "../../../api/EquipmentsAPI";
import styles from "./create-window.module.scss";
import {Input} from "../../../components/input/Input";
import {Button} from "../../../components/button/Button";
import {useDispatch, useSelector} from "react-redux";
import {addEquipmentToList} from "../../../store/operations/equipments-operations";
import {equipmentsSelector} from "../../../store/selectors/equipments-selector";
import {useAlert} from "../../../hooks/useAlert";

export const CreateWindow = ({closeModal}) => {
    const {equipmentsList, totalCount} = useSelector(equipmentsSelector)
    const [newName, setNewName] = useState("")
    const dispatch = useDispatch()
    const [successAlert, errorAlert] = useAlert()

    const newNameHandler = ({target}) => {
        setNewName(target.value)
    }

    const submitHandler = () => {
        if (newName.match(/^ +$/) || newName === '') {
            errorAlert({message: 'Equipment name can not be empty'})
        } else {
            new EquipmentsAPI().createEquipment(newName).then((res) => {
                dispatch(addEquipmentToList(equipmentsList, res.data, totalCount))
                successAlert('New equipment was created!')
                closeModal && closeModal()
            }).catch(errorAlert)
        }
    }

    return (
        <>
            <p className={styles.create_title}>Create new equipment</p>
            <Input placeholder={'Name'} className={styles.create_input} value={newName} onChange={newNameHandler}/>
            <section className={styles.create__buttons}>
                <Button onClick={submitHandler} title={'Apply'}/>
                <Button onClick={closeModal} title={'Cancel'} isGrey={true}/>
            </section>
        </>
    )
}