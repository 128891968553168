import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import cn from "classnames";

import { Button } from "../../../components/button/Button";
import { useAlert } from "../../../hooks/useAlert";
import WorkoutsAPI from "../../../api/WorkoutsAPI";
import { Multiselect, Select } from "../../../components/select-menu";
import workoutStyleIcon from "../../../assets/image/workout-style-icon.png";
import { workoutStyles } from "../../../assets/constants";
import styles from "./workout-page.module.scss";
import { Input } from "../../../components/input/Input";
import s from "../creation-page/workout-creation-page.module.scss";
function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const WorkoutPage = (props) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const ref = useRef(null);

  const [currentPosition, setCurrentPosition] = useState(0);
  const [workoutData, setWorkoutData] = useState(null);
  const [videoThumbnail, setVideoThumbnail] = useState(null);
  const [thumbnailImage, setThumbnailImage] = useState(null);
  const [thumbnailUpdated, setThumbnailUpdated] = useState(false);
  const [successAlert, errorAlert] = useAlert();

  const [priority, setPriority] = useState(10);
  const [difficulty, setDifficulty] = useState(0);
  const [workoutStyle, setWorkStyle] = useState("");
  const [parts, setParts] = useState([]);
  const [initialDifficulty, setInitialDifficulty] = useState(0);
  const deleteHandler = () => {
    new WorkoutsAPI()
      .deleteWorkout(id)
      .then((res) => {
        successAlert("Workout has been deleted");
        navigate("/dashboard/workouts");
      })
      .catch(errorAlert);
  };

  const difficultyResetHandler = () => {
    new WorkoutsAPI()
      .resetWorkoutDifficulty(id)
      .then((res) => {
        // successAlert("Workout's has been reset");
      })
      .catch(errorAlert);
  };
  const settingsHandler = (name, value) => {
    if (name == "style") {
      if (value != "") {
        setWorkStyle(value);
      }
    }
    if (name == "priority") {
      setPriority(value);
    }
    if (name == "difficulty") {
      setDifficulty(value);
    }
  };

  const inputHandler = (e) => {
    settingsHandler(e.target.name, e.target.value);
  };

  useEffect(() => {
    if (ref?.current) {
      const player = new Vimeo.Player(ref.current); // eslint-disable-line
      player.setCurrentTime(currentPosition);
    }
  }, [currentPosition]);

  useEffect(() => {
    new WorkoutsAPI()
      .getById(id)
      .then((res) => {
        setWorkoutData(res.data);
        const data = res.data;
        setPriority(data.priority);
        setDifficulty(data.difficulty);
        setInitialDifficulty(data.difficulty);
        const convertedStyle =
          data.style.charAt(0).toUpperCase() +
          data.style.slice(1).toLowerCase();
        setWorkStyle(convertedStyle);
        setParts(data?.video?.parts);
      })
      .catch((error) => {
        errorAlert(error);
        navigate("/dashboard/workouts", { replace: true });
      });
  }, [thumbnailUpdated]);
  const videoId = workoutData?.video?.link.split("/")[3];
  const thumbnailHandler = (e) => {
    var reader = new FileReader();
    reader.onloadend = function () {
      setThumbnailImage(reader.result);
    };
    reader.readAsDataURL(e?.target?.files[0]);
    const videoFile = new File(
      [e?.target?.files[0]],
      `baseWorkoutData.${e?.target?.files[0]?.name.split(".").pop()}`,
    );
    setVideoThumbnail(videoFile);
  };
  const saveChangesHandler = (e) => {
    new WorkoutsAPI()
      .updateInformation(
        {
          customThumbnail: videoThumbnail,
          difficulty: difficulty,
          priority: priority,
          workoutStyle: workoutStyle,
          parts: parts,
        },
        id,
      )
      .then((response) => {
        successAlert("successfully updated");
        setVideoThumbnail(videoThumbnail);
        setThumbnailImage(null);
        setThumbnailUpdated(!thumbnailUpdated);
      });
    if (difficulty != initialDifficulty) {
      difficultyResetHandler();
    }
  };
  const resetTargetHRHandler = (partIndex) => () => {
    new WorkoutsAPI()
      .resetAverageHR(id, partIndex)
      .then((res) => {
        setWorkoutData(res.data);
        const data = res.data;
        setPriority(data.priority);
        setDifficulty(data.difficulty);
        const convertedStyle =
          data.style.charAt(0).toUpperCase() +
          data.style.slice(1).toLowerCase();
        setWorkStyle(convertedStyle);
        setParts(data?.video?.parts);
      })
      .catch((error) => {
        errorAlert(error);
      });
  };
  const partsChangeHandler = (type, value, partId, nodeId) => {
    let _newPartsArray = [...parts];
    if (type == "hr") {
      _newPartsArray[partId].targetHR = value;
    } else if (type == "timeStart") {
      _newPartsArray[partId].nodes[nodeId].timeStart = value;
    } else if (type == "timeEnd") {
      _newPartsArray[partId].nodes[nodeId].timeEnd = value;
    }
    setParts(_newPartsArray);
  };
  console.log(workoutData?.video?.customThumbnail, "IMAGE");
  return (
    <>
      <p className={styles.workout__paragraph}>
        <span>Title: </span>
        {workoutData?.title}
      </p>
      <p className={styles.workout__paragraph}>
        <span>Instructor: </span>
        {workoutData?.instructor}
      </p>
      <p className={styles.workout__paragraph}>
        <span>Style: </span>
        {workoutData?.style}
      </p>
      <p className={styles.workout__paragraph}>
        <span>Body Part: </span>
        {Array.isArray(workoutData?.bodyPart)
          ? workoutData?.bodyPart.join(",")
          : workoutData?.bodyPart}
      </p>
      <p className={styles.workout__paragraph}>
        <span>Calories: </span>
        {workoutData?.calory}
      </p>
      <p className={styles.workout__paragraph}>
        <span>HR: </span>
        {workoutData?.hr}
      </p>
      <p className={styles.workout__paragraph}>
        <span>Equipment: </span>
        {workoutData?.equipments?.join(" ")}
      </p>
      <p className={styles.workout__paragraph}>
        <span>Prioritize when a watch is connected: </span>
        {capitalize(
          workoutData?.prioritizeWhenWatchConnected?.toString() || "",
        )}
      </p>
      <p className={styles.workout__paragraph}>
        <span>This is a Fitness Test Workout? : </span>
        {workoutData?.isFitnessTestVideo == undefined
          ? "No"
          : workoutData?.isFitnessTestVideo == true
            ? "Yes"
            : "No"}
      </p>

      <div style={{ marginTop: 10 }}>
        <Select
          placeholder={"Click to select workout style"}
          icon={workoutStyleIcon}
          required={true}
          onSelect={settingsHandler}
          name={"style"}
          selectClassName={s.wrapper__video__settings__workout_style}
          options={workoutStyles.map(({ title }, index) => ({
            title,
            _id: index,
          }))}
          value={{ title: workoutStyle }}
        />
      </div>
      <fieldset className={s.wrapper__video__settings__difficulty}>
        <label htmlFor={"difficulty"}>Difficulty ({difficulty})</label>
        <Input
          required={true}
          value={difficulty}
          onChange={inputHandler}
          name={"difficulty"}
          type={"range"}
          min={1}
          max={10}
          step={1}
        />
      </fieldset>
      <fieldset className={s.wrapper__video__settings__difficulty}>
        <label htmlFor={"priority"}>Priority ({priority})</label>
        <Input
          required={true}
          value={priority}
          onChange={inputHandler}
          name={"priority"}
          type={"range"}
          min={1}
          max={10}
          step={1}
        />
      </fieldset>

      {workoutData && (
        <div
          className={styles.workout__player}
          key={thumbnailUpdated.toString()}
        >
          <iframe
            ref={ref}
            src={`https://player.vimeo.com/video/${workoutData?.video?.link?.match(
              /[0-9]+/,
            )}`}
            frameBorder="0"
            webkitallowfullscreen
            mozallowfullscreen
            allowFullScreen
          />
          <div className={styles.hasProfileUploadWrapper}>
            <div className={styles.profilePhotoWrapper}>
              {(thumbnailImage !== null ||
                workoutData?.video?.customThumbnail) &&
                thumbnailImage != "" && (
                  <div className={styles.hasImage}>
                    <span className={styles.uploadImg}>
                      <img
                        src={
                          thumbnailImage !== null
                            ? thumbnailImage
                            : `${process.env.REACT_APP_API_BASE_URL_STATIC}${workoutData?.video?.customThumbnail}`
                        }
                        alt={"Thumbnail_preview"}
                      />
                    </span>
                  </div>
                )}
              <div>
                <div className={styles.hasUploadButton}>
                  <label htmlFor="uploadImage">Update Thumbnail</label>
                  <input
                    type="file"
                    id={`uploadImage`}
                    accept=".png, .jpg"
                    onChange={thumbnailHandler}
                  />
                </div>
              </div>
            </div>
          </div>
          {workoutData?.video?.parts?.map(
            ({ name, nodes, targetHR, averageHR, exercise }, partIndex) => {
              const _fieldHandler = ({ target: { name, value } }) => {
                const [nodeId, type] = name.split("_");
                partsChangeHandler(type, value, partIndex, nodeId);
              };
              return (
                <table className={styles.workout__player__parts} key={name}>
                  <thead>
                    <tr>
                      <th>Difficulty</th>
                      <th>Time Start</th>
                      <th>Time End</th>
                      <th>Target HR</th>
                      <th>Average HR</th>
                      <th>Exercise</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {nodes.map(({ difficult, timeStart, timeEnd }, nodeId) => {
                      return (
                        <tr
                          className={styles.workout__player__parts__node}
                          key={nodeId}
                          onClick={setCurrentPosition.bind(null, timeStart)}
                        >
                          <td>{difficult}</td>
                          <td width={"15%"}>
                            <Input
                              onChange={_fieldHandler}
                              name={`${nodeId}_timeStart`}
                              value={timeStart}
                            />
                          </td>
                          <td width={"15%"}>
                            <Input
                              onChange={_fieldHandler}
                              name={`${nodeId}_timeEnd`}
                              value={timeEnd}
                            />
                          </td>
                          <td width={"15%"}>
                            <Input
                              onChange={_fieldHandler}
                              name={`${nodeId}_hr`}
                              value={targetHR}
                            />
                          </td>
                          <td width={"10%"}>{averageHR}</td>
                          <td> {exercise}</td>
                          <td width={"15%"}>
                            <Button
                              isGrey={true}
                              onClick={resetTargetHRHandler(partIndex)}
                            >
                              Reset target HR
                            </Button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              );
            },
          )}
        </div>
      )}
      <div className={styles.buttonsWrapper}>
        <Button onClick={deleteHandler} title={"Delete workout"} />
        <Button onClick={saveChangesHandler} type="button">
          Save Changes
        </Button>
      </div>
    </>
  );
};
