import {Outlet} from "react-router-dom";
import {ContentWrapper} from "../content-wrapper/ContentWrapper";
import {withAuth} from "../../hocs/withAuth";

import styles from './admin-panel-layout.module.scss'
import {Menu} from "../menu/Menu";

export const AdminPanelLayout = withAuth(props => {
    return (
        <section className={styles.wrapper}>
            <Menu/>
            <ContentWrapper>
                <Outlet/>
            </ContentWrapper>
        </section>
    )
})
