import {
    REMOVE_PROFILE_DATA,
    SAVE_PROFILE_DATA,
    SET_PROFILE_LOADING_STATUS,
    UPDATE_PROFILE_DATA
} from "../action-types/profile-action.types";

export const saveUserData = (userData) => {
    return {type: SAVE_PROFILE_DATA, payload: userData}
}

export const removeUserData = () => {
    return {type: REMOVE_PROFILE_DATA}
}

export const updateUserData = (newUserData) => {
    return {type: UPDATE_PROFILE_DATA, payload: newUserData}
}

export const setLoadingStatus = (loadingStatus) => {
    return {type: SET_PROFILE_LOADING_STATUS, payload: loadingStatus}
}