import styles from './multiselect.module.scss'
import {useEffect, useRef, useState} from "react";

import removeIcon from '../../../assets/image/cross-icon.svg'
import {useOutsideClick} from "../../../hooks/useOutsideClick";

export default ({options, values = [], onSelect, selectClassName, name, menuClassName, selectedClassName, placeholder, icon, required = false}) => {
    const wrapperRef = useRef(null)
    const [selectedItems, setSelectedItems] = useState(values)
    const [isMenuOpen, setIsMenuOpen] = useState(false)

    useOutsideClick(wrapperRef, () => setIsMenuOpen(false))

    useEffect(() => {
        onSelect && onSelect(name, selectedItems.map(({title}) => title))
    }, [selectedItems])

    const addOptionClickHandler = (option) => {
        setSelectedItems([...selectedItems, option])
    }

    const removeOptionHandler = ({_id}) => {
        setSelectedItems(selectedItems.filter(i => {
            return i._id !== _id
        }))
    }

    return (
        <div ref={wrapperRef} className={`${styles.multiselect} ${selectClassName}`}>
            {!selectedItems.length && <p className={styles.multiselect_placeholder}>{placeholder}</p>}
            {icon && <img src={icon} className={styles.multiselect_icon} alt={icon.name}/>}
            <div onClick={setIsMenuOpen.bind(null, true)}
                 className={`${styles.multiselect__selected} ${selectedClassName}`}>
                <input style={{
                    position: 'absolute',
                    left: 0,
                    top: 0,
                    outline: 'none',
                    border: 'none',
                    background: 'transparent',
                    color: "transparent",
                    opacity: selectedItems.length ? 1 : 0,
                }} value={selectedItems.length ? '1' : ''} required={required}/>
                {selectedItems.map(item => {
                    return (
                        <p onClick={removeOptionHandler.bind(null, item)} className={styles.multiselect__selected_option} key={'item'+item._id}>
                            {item.title}
                            <img src={removeIcon} alt={`remove_${item.title}`}/>
                        </p>
                    )
                })}
            </div>
            {isMenuOpen && (
                <ul className={`${styles.multiselect__menu} ${menuClassName}`}>
                    {options.filter(op => {
                        return !selectedItems.map(({_id}) => _id).includes(op._id)
                    }).map(option => <li key={'option' + option._id} onClick={addOptionClickHandler.bind(null, option)} className={styles.multiselect__menu_option}>{option.title}</li>)}
                </ul>
            )}
        </div>
    )
}
