import AuthAPI from "../AuthAPI";
import {getLocalRefreshToken, rememberLocalAuthTokens, removeLocalAuthTokens} from "../../services/token.service";

export const onResponseFulfilled = (response) => {
    return response
}

export const onResponseReject = async error => {
    if (error.response.status === 403) {
        removeLocalAuthTokens()

        window.location.reload()
    } else if (error.response.status === 401) {
        removeLocalAuthTokens()

        window.location.reload()
    }
    return Promise.reject(error);
}
