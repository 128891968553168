import AuthAPI from "../../api/AuthAPI";
import {removeUserData, saveUserData, setLoadingStatus} from "../actions/profile-actions";
import {rememberLocalAuthTokens, removeLocalAuthTokens} from "../../services/token.service";
import UsersAPI from "../../api/UsersAPI";

export const authorizeUser = (credentials, onSuccess, onError) => {
    return dispatch => {
        dispatch(setLoadingStatus(true))

        new AuthAPI().sendLoginRequest(credentials).then(({data}) => {
            dispatch(saveUserData(data?.user))
            rememberLocalAuthTokens(data?.auth)
            onSuccess && onSuccess()
        }).catch(error => {
            onError && onError(error)
        }).finally(() => dispatch(setLoadingStatus(false)))
    }
}

export const requestUserData = (onSuccess, onError) => {
    return dispatch => {
        dispatch(setLoadingStatus(true))

        new UsersAPI().getUserById('me').then(({data}) => {
            dispatch(saveUserData(data))
            onSuccess && onSuccess()
        }).catch(error => {
            removeLocalAuthTokens()
            dispatch(removeUserData())
            onError && onError(error)
            window.location.reload()
        }).finally(() => dispatch(setLoadingStatus(false)))
    }
}

export const logoutUser = (onSuccess, onError) => {
    return dispatch => {
        dispatch(setLoadingStatus(true))

        new AuthAPI().sendLogOutRequest().then(({data}) => {
            removeLocalAuthTokens()
            dispatch(removeUserData())
            onSuccess && onSuccess()
        }).catch(error => {
            removeLocalAuthTokens()
            onError && onError(error)
            window.location.reload()
        }).finally(() => dispatch(setLoadingStatus(false)))
    }
}
