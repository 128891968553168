import {Link, useNavigate, useParams} from "react-router-dom";
import {useCallback, useEffect, useState} from "react";
import ChallengesAPI from "../../../api/ChallengesAPI";
import {addAlertContent} from "../../../store/actions/alerts-actions";
import {useDispatch} from "react-redux";
import styles from './challenge-page.module.scss'
import {Button} from "../../../components/button/Button";
import {useAlert} from "../../../hooks/useAlert";
import { useSelector } from "react-redux";
import { workoutsSelector } from "../../../store/selectors/workouts-selector";
import { getWorkoutsListForAll } from "../../../store/operations/workouts-operations";
import Select from 'react-select';

export const ChallengePage = (props) => {
    const {id} = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {workoutsListForAll, isLoading, totalCount} = useSelector(workoutsSelector)
    const [optionList, setOptionList] = useState([])
    const [challengeData, setChallengeData] = useState(null)
    const [selectedOption, setSelectedOption] = useState('chocolate');

    const [successAlert, errorAlert] = useAlert()

    const options = [
      { value: 'chocolate', label: 'Chocolate' },
      { value: 'strawberry', label: 'Strawberry' },
      { value: 'vanilla', label: 'Vanilla' },
    ];
    const removeHandler = () => {
        new ChallengesAPI().deleteChallenge(id).then(res => {
            successAlert(res.data.message)
            navigate('/dashboard/challenges')
        }).catch(errorAlert)
    }

    useEffect(() => {
        dispatch(getWorkoutsListForAll())
        new ChallengesAPI().getChallengeById(id).then(({data}) => setChallengeData(data)).catch(error => {
            errorAlert(error)
            navigate('/dashboard/challenges', {replace: true})
        })
    }, [])

    useEffect(()=>{
        const list = workoutsListForAll.map(item=>{ return {value:item._id,label:item.title}})
        setOptionList(list)
    },[workoutsListForAll])
    
    const onChangeWorkout = (val, cur_index, isAlternative) => {
       if(isAlternative == false) {
        let newChallengeData = Object.assign({}, challengeData)
         let workoutsData = workoutsListForAll.filter(item=>item._id == val.value)
         if(workoutsData.length == 0)
            return 
         newChallengeData.workouts[cur_index] = workoutsData[0]
         newChallengeData.thumbnail = newChallengeData.workouts[0].video.thumbnail
         setChallengeData(newChallengeData)
      
        }
       else {
        let newChallengeData = Object.assign({}, challengeData)
         let workoutsData = workoutsListForAll.filter(item=>item._id == val.value)
         if(workoutsData.length == 0)
            return 
 
         newChallengeData.altWorkouts[cur_index] = workoutsData[0]
         newChallengeData.thumbnail = newChallengeData.altWorkouts[0].video.thumbnail
         setChallengeData(newChallengeData)

       }
    }
 
    const updateHandler = () =>{
        if(challengeData.type == 'CHALLENGE') {
            errorAlert({message: 'You can only edit Custom Plan'})
            return
        }
        new ChallengesAPI().updateChallenge(id, {
            type : challengeData.type,
            title: challengeData.title,
            description : challengeData.description,
            difficulty : challengeData.difficulty,
            isPrimary : challengeData.isPrimary,
            thumbnail : challengeData.thumbnail,
            thumbnailName : challengeData.thumbnailName,
            workouts : challengeData.workouts.map(item=> item == null ? null : item._id),
            altWorkouts : challengeData.altWorkouts.map(item=> item == null ? null : item._id)
        }).then(res => {
            successAlert('Success')
        }).catch(errorAlert)
    }
    return (
        <>
            <section className={styles.challenge__basic}>
                <img className={styles.challenge__basic_thumbnail} src={challengeData?.thumbnail} alt={'thumbnail'}/>
                <div className={styles.challenge__basic__texts}>
                    <p className={styles.challenge__basic__texts__paragraph}><span>Title:</span> {challengeData?.title}
                    </p>
                    <p className={styles.challenge__basic__texts__paragraph}>
                        <span>Description:</span> {challengeData?.description}</p>
                    <p className={styles.challenge__basic__texts__paragraph}>
                        <span>Difficulty:</span> {challengeData?.difficulty}</p>
                    <p className={styles.challenge__basic__texts__paragraph}>
                        <span>Is Primary:</span> {`${challengeData?.isPrimary}`}</p>
                </div>
            </section>
            <section className={styles.challenge__workouts}>
                <p className={styles.challenge__workouts_header}>Workouts</p>

                <div className={styles.challenge__workouts__list}>
                    { optionList.length > 0 && challengeData?.workouts?.map((w, index) => {
                        console.log('updating...')
                        let val = optionList.filter(item=>item.value == w?._id)
                        if(val.length > 0)
                            val = val[0]
                        else
                            val = null
                        return w ? (
                             
                            <div className={styles.challenge__workouts__list__tile}>
                                <Link target="_blank" rel="noopener noreferrer"
                                    to={`/dashboard/workouts/${w?._id}`} key={w?._id}>
                                    <p>Day {index + 1 + challengeData?.offsetDays}</p>
                                    <img src={w?.video?.thumbnail} alt={w?.title}/>
                                </Link>
                                <Select
                                    value={val}
                                    options={optionList}
                                    onChange={(val)=>onChangeWorkout(val, index, false)}
                                    isSearchable = {true}
                                    className={styles.challenge__workouts__list__react_select}
                                    classNamePrefix="react-select"
                                />
                            </div>
                        ) : (
                            <div key={w?._id} className={styles.challenge__workouts__list__tile}>
                                <p>Day {index + 1 + challengeData?.offsetDays}</p>
                                <span>Day off</span>
                            </div>
                        )
                    })} 
                </div>
            </section>
            <section className={styles.challenge__workouts}>
                <p className={styles.challenge__workouts_header}>Alternative Workouts</p>

                <div className={styles.challenge__workouts__list}>
                    { optionList.length > 0 && challengeData?.altWorkouts?.map((w, index) => {
                        let val = optionList.filter(item=>item.value == w?._id)
                        if(val.length > 0)
                            val = val[0]
                        else
                            val = null
                        return w ? (
                            <div className={styles.challenge__workouts__list__tile}>
                                <Link target="_blank" rel="noopener noreferrer"
                                    to={`/dashboard/workouts/${w?._id}`} key={w?._id}>
                                    <p>Day {index + 1 + challengeData?.offsetDays}</p>
                                    <img src={w?.video?.thumbnail} alt={w?.title}/>
                                </Link>
                                <Select
                                    value={val}
                                    options={optionList}
                                    onChange={(val)=>onChangeWorkout(val, index, true)}
                                    isSearchable = {true}
                                    className={styles.challenge__workouts__list__react_select}
                                    classNamePrefix="react-select"
                                />
                            </div>
                        ) : (
                            <div key={w?._id} className={styles.challenge__workouts__list__tile}>
                                <p>Day {index + 1 + challengeData?.offsetDays}</p>
                                <span>Day off</span>
                            </div>
                        )
                    })}
                </div>
            </section>
            <div className={styles.challenge__footer}>
                <Button className={styles.challenge_remove_button} onClick={updateHandler} title={'Update challenge'}/>
                <Button className={styles.challenge_remove_button} onClick={removeHandler} title={'Delete challenge'}/>
            </div>
        </>
    )
}
